import React, {useState} from 'react'

import './Followup.css'

import AddNew from './AddNew'

const Followup =(props) =>
{
    const data = props.data
    const [msg , setMsg] = useState();

    const addNew = (e) =>{
        e.preventDefault();
        document.querySelector(".newData").style.display = "block";
        

    }
    let i= 1;
    
    const Fields = data.map((user) =>
    

    <div className="uCard" key={user.id}>
        
        <div className="linediv">
        <p>Followup No: {i++}</p>
        <p className="line">Time: {user.creation_time.toString()}</p>
        <p className="line">Added By: {user.addedBy}</p>
        </div>
        {user.date && <p className="line">Next Follow Up (If any): {user.date.toString()}</p>}
        <p>Description: {user.description}</p> 
        
    </div>
    
    );


    return(
        <div className = "follows">
            <h4 className = "followheader">Follow Up History</h4>
            {data.length == 0 &&  <h5>No History Found</h5>}
            <div>{Fields}</div>
            <div className="newData"><AddNew f={props.f} id={props.id}/></div>
            <button className="addnew" onClick={addNew}>Add New</button>
        </div>

    )
}
export default Followup;