import React, {useState, useEffect} from 'react';
import './DemoLogin.css'

import Loading from '../Component/Loading'
import Activity from './Activity';
import { Form, Row, Col} from 'react-bootstrap';
import Taskbar from '../Component/taskbar'


const DemoLogin = (props) => {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    
    const [loggedIn, setLoggedIn] = useState(false);
    
    const [msg, setMsg] = useState();
    const [isLoading, setIsLoading] = useState();
    
    let token = localStorage.getItem("token")
   


    const loginHandler = async e => {
        
        setIsLoading(true)

        try{

        const response = await fetch('https://mapi.sumon.com.bd/api/login' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                password: password,
                
            })
            
        });
        
        if(response.ok) {
            setLoggedIn(true)
            const responseData = await response.json();
            localStorage.setItem("token", JSON.stringify(responseData.token))
            
        }
        else {setMsg("Wrong Credentials")}
        setIsLoading(false)
        
        
        
    }
    catch {
        setIsLoading(false)
    }
    
    
    };
    const handleEmailChange = e => {
        setUsername(e.target.value);
    }
    const handlePasswordChange = e => {
        setPassword(e.target.value);
    }
    



    return(
        <React.Fragment>
            
            {!loggedIn && <Taskbar/>}
            {!isLoading && loggedIn && <Activity login={loggedIn}/>}
            {isLoading && <Loading/>}
            {!isLoading && !loggedIn && 
            
        <div className="App">   
        
            <div className="mt-3">
            <Row className="g-2">
               <Col md>
            <Form.Control name="email" className="inputdata" onChange={handleEmailChange} type="email" required placeholder="Username"></Form.Control>

            <Form.Control onChange={handlePasswordChange} className="inputdata" name="password" type="password" required placeholder="Password"></Form.Control>
        
            </Col>
            </Row>
            <button className="centerL" onClick={loginHandler}>{'Login'}</button>
            
            <p className="red center">{msg}</p>

            
            </div>
            
            
    </div>
            }
            


    
    </React.Fragment>
    )
}

export default DemoLogin;