import React, {useState} from 'react'

import './Followup.css'


const Followup =(props) =>
{
    const data = props.data
    const [msg , setMsg] = useState();
    let i=1;

    const Fields = data.map((user) =>
    

    <div className="uCard" key={user.id}>
        <div className="linediv">
        <p>Serial No: {i++}</p>
        <p className="line">Biodata ID: <a onClick="" href={"/admin/"+user.post_name}>{user.post_name}</a></p> 
        <p className="line">Last FollowUp: {user.creation_time.toString()}</p>
        <p className="line">Next Followup: {user.date.toString()}</p>
        <p className="line">Added By: {user.addedBy}</p>
        </div>
        
        <p>Description: {user.description}</p> 
        
    </div>
    
    );
    



    return(
        <div className = "follows">
            
            {data.length == 0 &&  <h5>No History Found</h5>}
            <div>{Fields}</div>
            
        </div>

    )
}
export default Followup;