import React, {useState, useEffect} from 'react'
import Loading from './Loading'
import './AddNew.css'
import { Form } from 'react-bootstrap';

const AddNew = (props) => {

    
    const [isLoading, setIsLoading] = useState();
    

    const [desc, setDesc] = useState();
    const [status, setStatus] = useState('N');
    const [date1, setDate] = useState();
    
    const [msg, setMsg] = useState();
    
    
    const descChange = e => {
        setDesc(e.target.value);
    }
    const statusChange = e => {
        setStatus(e.target.value);
    }
    const dateChange = e => {
        setDate(e.target.value);
    }

    const addNewFollowup = async e => {
        let d = new Date(date1);
        /* Date format you have */
        let d1 = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        /* Date converted to MM-DD-YYYY format */
        console.log(d1)
        const current = new Date();
        const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
        const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
        const fulltime = date+" "+time;
        console.log(fulltime)

        setIsLoading(true);
        let token = localStorage.getItem("token");
        try{
            
        const response = await fetch('https://mapi.sumon.com.bd/api/addfollowup' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
            body: JSON.stringify({
                description: desc, time: fulltime,
                status, 
                id: props.id,
                date: d1
            })
        });
        
        const responseData = await response.json();
        console.log(response);
        if(response.ok) {
            
            props.f(props.id)
            setMsg("Followup Added");
        }
        else if(response.status==405) {setMsg("Fill all the fields correctly");}
        else if(response.status==403) {setMsg("Unauthorized");}
        else {
            console.log("Error")
        }
          
        }
        catch {
            console.log("Catch")
        }
        setIsLoading(false)
    }

    return(
    <div>
        {isLoading && <Loading/>}
        {!isLoading &&
        
        <div className="addnewdiv App">
            <p className="hello">Add a New FollowUp</p>
            <textarea name="description" value={desc} className="ndata" id="txt" onChange={descChange} type="textarea" rows="4" cols="50" placeholder="Description"></textarea>
            
            <Form.Control name="date" value={date1} className="ndata" onChange={dateChange} type="date" placeholder="Date"></Form.Control>
            <label className="statuslabel">Succesfully Found the Life Partner?</label>
            <select className="statusdata" onChange={statusChange}>
                <option value="N">No</option>
                <option value="Y">Yes</option>
            </select>
            
            <button id="centerbtn"onClick={addNewFollowup } type="submit">Submit</button>
           
            
            <p className="red center">{msg}</p>
        </div>  
            }
    </div>
    )
}

export default AddNew