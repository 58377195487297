import React, {useState, useEffect} from 'react'
import './userCard.css'

const UserCard =(props) =>
{
    const data = props.data
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [regDate, setRegDate] = useState();
    const [username, setUsername] = useState();
    const [folder, setFolder] = useState();
    const [img, setImg] = useState();
    const [bio, setBio] = useState();

    
    function App() {
        useEffect(() => {
            setUsername(data.data.user_login);
            setRegDate(data.data.user_registered);
            setName(data.data.display_name)
            setEmail(data.data.user_email);
            if(data.folder!="none") setFolder(data.folder);
            if(data.img!="none") setImg(data.img);
            if(data.biodata!="none") setBio(data.biodata);
        }, []);
      } 
      App();
    


    return(
        <div className="User card">
            <img className="cardimg" src={`http://localhost/matrimony/wp-content/uploads/${folder}/${img}` }></img>
            <br></br>
            <p>Name: {name}</p>
            <p>Email: {email}</p>
            <p>Username: {username}</p>
            <p>Biodata ID: {bio}</p>
            <p>Registered On: {regDate}</p>
            
            
        </div>

    )
}
export default UserCard;