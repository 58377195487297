import React, {useState, useEffect} from 'react'
import './Activity.css'
import Loading from '../Component/Loading'
import Followup from '../Component/Followup2'
import Taskbar from '../Component/taskbar'
import {useParams} from "react-router-dom"
import { Form } from 'react-bootstrap';
import UserCard from '../Component/userCard'
import './Followup.css'

const OnFollowUp = (props) => {

    const [isLoading, setIsLoading] = useState();

    const [msg, setMsg] = useState();
    const [data, setData] = useState();
    


    
    
   
    const searchfolloups = async () =>{
        
        setIsLoading(true);
        let token = localStorage.getItem("token");
;        try{
        const response = await fetch('https://mapi.sumon.com.bd/api/catchfollowup' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                
            })
        });
        
        const responseData = await response.json();
        //setData(responseData.data)
        if(response.ok) {
            
            setData(responseData.data);
            setMsg();
            
            
        }
        else if(response.status == 404) {setMsg("No User Found")}
        else {
            console.log("Error")
        }
          
        }
        catch {
            console.log("Catch")
        }
        setIsLoading(false)
    }

    function App() {
        useEffect(() => {
            searchfolloups();
        }, []);
    } 
    App();


    



    
    return (
        <React.Fragment>
            <Taskbar/>
        {isLoading && <Loading/>}
        {!isLoading &&
        <div>
            
            
        {data && <Followup data={data}/>}
           
           
        </div>
        }
        
        </React.Fragment>
    )
}

export default OnFollowUp