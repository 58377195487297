import React, {useState, useEffect} from 'react';
import Home from './pages/home'
import DemoLogin from './pages/DemoLogin'
import Taskbar from './Component/taskbar'
import Activity from './pages/Activity';
import NotFound from './pages/notFound'
import Loading from './Component/Loading'
import OnFollowUp from './pages/FollowUp'
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom';



const App = () => {

  
  return (
    <React.Fragment>
    
    <Router>
      <Switch>
      <Route path="/loading" exact>
      <Loading/>
      </Route>

      <Route path="/" exact>
      <Taskbar/>
        <Home/>
      </Route>
      <Route path="/login" exact>
        <DemoLogin/>
      </Route>
      <Route path="/admin/" exact>
        
        <Activity/>
      </Route>
      <Route path="/admin/:id" exact>
        <Activity/>
      </Route>

      <Route path="/onfollowup" exact>
        
        <OnFollowUp/>
      </Route>

      <Route path="/">
      <Taskbar/>
        <NotFound/>
      </Route>
      </Switch>
      </Router>
      
      </React.Fragment>
  );
}

export default App;
