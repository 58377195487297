import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';


import App from './App'


ReactDOM.render(
   <App/>,
   document.getElementById('root')
 );
 