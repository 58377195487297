import React, {useState, useEffect} from 'react'
import './Activity.css'
import Loading from '../Component/Loading'
import Followup from '../Component/Followup'
import {useParams} from "react-router-dom"
import { Form } from 'react-bootstrap';
import UserCard from '../Component/userCard'
import Taskbar from '../Component/taskbar'

const Activity = (props) => {

    const bioid = useParams().id;
    const [isLoading, setIsLoading] = useState();
    //const [data, setData] = useState();
    //setData(props.data)
    const [searchData, setSearchData] = useState();
    const [searchType, setSearchType] = useState();
    
    const [id, setId] = useState();
    const [followData, setFollowData] = useState();

    const [msg, setMsg] = useState();
    

    const [responseDataa, setResponseDataa] = useState();

    let token = localStorage.getItem("token")
    
    let data = props.data

    const searchDataChange = e =>{
        setSearchData(e.target.value);
    }
    const searchUser = async (e, type, id) =>{
        
        if(type==null && id==null){
            type=searchType;
            id=searchData;
        }
        console.log(type, id);
        setIsLoading(true);
        let token = localStorage.getItem("token");
;        try{
        const response = await fetch('https://mapi.sumon.com.bd/api/searchuser' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                type, id
            })
        });
        
        const responseData = await response.json();
        //setData(responseData.data)
        if(response.ok) {
            
            setId(responseData.data.ID);
            
            setResponseDataa(responseData);
            searchFollowUp(responseData.data.ID);
            setMsg();
            
            
        }
        else if(response.status == 404) {setMsg("No User Found")}
        else {
            console.log("Error")
        }
          
        }
        catch {
            console.log("Catch")
        }
        setIsLoading(false)
    }
    function App() {
        useEffect(() => {
            
            if(bioid!=null){
                
                searchUser(null, "biodata", bioid);
            }
        }, []);
    } 
    App();

    
    


    const searchFollowUp = async (id) =>{
        setId(id);
        
        setIsLoading(true);
        let token = localStorage.getItem("token");
        try{
            console.log(id)
        const response = await fetch('https://mapi.sumon.com.bd/api/followup' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            
            body: JSON.stringify({
                id
            })
        });
        
        const responseData = await response.json();
        //setData(responseData.data)
        if(response.ok) {
            setMsg();
            setFollowData(responseData.data)
        }
        //else if(response.status == 404) {setMsg("No Users Found")}
        else {
            console.log("Error")
        }
          
        }
        catch {
            console.log("Catch")
        }
        setIsLoading(false)
    }

    const searchTypeChange = (data) =>{
        document.querySelector(".activity-body").style.display = "block";
        setSearchType(data)

    }



    
    return (
        <React.Fragment>
            <Taskbar/>
        {isLoading && <Loading/>}
        {!isLoading &&
        <div>
            <div className="activity-header">
                <button onClick={() =>searchTypeChange("Email")} className="searchbtn">Search By Email</button>
                <button onClick={() =>searchTypeChange("Phone")} className="searchbtn">Search By Phone</button>
                <button onClick={() =>searchTypeChange("Username")} className="searchbtn">Search By Username</button>
                <button onClick={() =>searchTypeChange("Biodata")} className="searchbtn">Search By Biodata</button>
                
            </div>
            <div className="activity-body">
                <Form.Control name="status" className="ndata" onChange={searchDataChange} type="text" placeholder={"Enter User " + searchType}></Form.Control>
            
                <button id="centerbtn"onClick={searchUser } type="submit">Search User</button>
            </div>
            {msg && <h4>{msg}</h4>}
            
            {responseDataa && <div className="activity-user">
                <UserCard data={responseDataa}></UserCard>
            </div>}
            
            { followData && <div id="followup"><Followup f={searchFollowUp} id={id} data={followData}/></div>}
        </div>
        }
        
        </React.Fragment>
    )
}

export default Activity